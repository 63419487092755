<template>
    <div>
        <CmpRegisterSN />
    </div>
</template>

<script>
import CmpRegisterSN from '@/components/login/RegisterSocialNetwork.vue'

export default {
    name: 'VCmpRegisterSN',
    components: {
        CmpRegisterSN,
    }
}
</script>
