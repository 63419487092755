<template>
    <section>
            

        <div class="container">

        <transition name="fade">
            <div v-if="!show">
            <div
            class="is-block has-text-centered"
            style="margin:auto"
            v-if="loader"
            >
            <div class="loaderCentrado">
            <pulse-loader :loading="loader" :color="colorLoader"></pulse-loader> 
            </div>
        </div>
        </div>
        </transition>

        <div v-if="!loader">
        <div class="notification mt-12">
            <div class="columns is-centered">
                        
                <div class="column is-4">

                    <form @submit.prevent="submit">
                 
                            <p class="colorPrimario mb-1 has-text-centered"> Iniciar sesión </p>


                            <!-- Provincia -->
                            <b-field label="Telefono" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Telefono"
                                v-model="$v.telefono.$model"
                                :class="{'SuccessInput' : !$v.telefono.$invalid, 'NormalInput' : $v.telefono.$invalid}"
                                 >
                                </b-input>
                            </b-field>
                            <p v-if="$v.telefono.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>                            

                            <div class="mb-2 is-block"></div>

                            <!-- Provincia -->
                            <b-field label="Provincia" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Provincia"
                                v-model="$v.provincia.$model"
                                :class="{'SuccessInput' : !$v.provincia.$invalid, 'NormalInput' : $v.provincia.$invalid}"
                                 >
                                </b-input>
                            </b-field>
                            <p v-if="$v.provincia.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>                            

                            <div class="mb-2 is-block"></div>
                            
                            <!-- Ciudad -->
                            <b-field label="Ciudad" :label-position="labelPosition">
                                <b-input type="text"
                                placeholder="Ciudad"
                                v-model="$v.ciudad.$model" 
                                :class="{'SuccessInput' : !$v.ciudad.$invalid, 'NormalInput' : $v.ciudad.$invalid}"
                                 >
                                </b-input>
                            </b-field>        
                            <p v-if="$v.ciudad.$error === true" class="subtitle is-7 mt-1 colorSoftRed">Campo requerido</p>                         

                            <div class="mb-2 is-block"></div>

                            <!-- Boton -->
                            <div class="column has-text-centered">

                                      <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorPrimary has-text-centered colorWhite"
                                      value="Crear cuenta"
                                      v-on:click="show = !show"
                                      v-if="this.$v.$invalid === false"
                                      ></b-button>

                                      <b-button tag="input"
                                      native-type="submit"
                                      class="bgColorPrimary has-text-centered colorWhite"
                                      value="Crear cuenta"
                                      v-on:click="show = !show"
                                      v-if="this.$v.$invalid === true"
                                      disabled
                                      >Crear cuenta</b-button>
{{from}}
                            </div>
                                
                    </form>
                    </div>
          
                </div>
          
                </div>

            </div>
    
        </div>

     
    </section>
</template>

<script>

import router from '@/router'
import { db } from "@/firebase"
import { mapActions, mapState, mapGetters } from "vuex"
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
    
    name: 'CmpRegisterSN',

    data() {
        return {
            show: true,
            labelPosition: 'on-border',
            uid: '',
            email: '',
            telefono: '',
            provincia: '',
            ciudad: '',
            from: ''
        }
    },

    components: {
        PulseLoader,
    },

    created() {

         this.checkRegisterUserRS({
               email: this.user.email,
               uid: this.user.uid
         });

    },

    //Validacion con vuevalidate
    validations: {
        telefono: {
            required,
            minLength: minLength(4)
        },
        provincia: {
            required,
            minLength: minLength(4)
        },
        ciudad: {
            required,
            minLength: minLength(4)          
        },
    },


    methods: {
  
            // Recibo las actions
            ...mapActions('sessions',['createUserRS', 'checkRegisterUserRS']),

            submit() {
          //  console.log('submit!')
            
            this.$v.$touch()
            if (this.$v.$invalid) {
             //  console.log('Se genero un error');
            } else {
              //  console.log('Email antes de ejecutar funcion:' + this.user.email);
              //  console.log('Nombre antes de ejecutar funcion:' + this.user.name);
                this.createUserRS({
                    name: this.user.name,
                    uid: this.user.uid,
                    email:this.user.email,
                    fotoPerfil: this.user.fotoPerfil,
                    telefono: this.telefono,
                    provincia: this.provincia,
                    ciudad: this.ciudad,
                });
              //  console.log('Editando información');
            }
            },
     },


     computed: { 
        ...mapState('generalFunctions',['error', 'loader', 'colorLoader']),
        ...mapState('sessions',['user']),
     }


}
</script>

<style scoped>

/* Loader */
/* Loader */

.loaderCentrado {
    display:block !important;
    margin:auto !important;
    width:60px !important;
    margin-top:40px!important;
}

.textoCargando {
       color:#ff7850!important;
       font-weight:600!important; 
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Colors text */
/* Colors text */

.colorPrimary {
    color:#8752aa!important;
}

.colorSecondary {
    color:#ff7850!important;
}

.colorTertiary {
    color:#ffc627!important;
}

.colorGrey {
    color:rgb(112, 112, 112)!important;
}

.colorSoftRed {
    color:rgb(219, 53, 53)!important;
}


.colorRed {
    color:red!important;
}

.colorYellow {
    color:rgb(233, 116, 21);
}

.colorSuccess {
    color:seagreen!important;
}

.colorWhite {
    color:rgb(255, 255, 255)!important;
}


/* Colors bg */
/* Colors bg */

.bgColorPrimary {
    background-color:#8752aa!important;
}

.bgColorSecondary {
    background-color:#ff7850!important;
}

.bgColorTertiary {
    background-color:#ffc627!important;
}

.bgColorSoftRed {
    background-color:#f64444!important;
}

.bgColorSoftBlue {
    background-color:#3d40fd!important;
}


/* Texto */
/* Texto */

.fontWeigth300 {
    font-weight:300!important;
}

.fontWeigth400 {
    font-weight:400!important;
}

.fontWeigth600 {
    font-weight:600!important;
}

.fontWeigth700 {
    font-weight:700!important;
}


/* Bordes */
/* Bordes */

.borderPrimary {
    border-color:#8752aa!important;
}

.borderSecondary {
    border-color:#ff7850!important;
}

.borderTertiary {
    border-color:#ffc627!important;
}


/* Botoness */
/* Botones */


.button:hover, .button.is-hovered {
    border-color: transparent!important;
    color: #363636;
}

/* Margins */
/* Margins */

.mt-1 {
    margin-top:10px!important;
}

.mb-1 {
    margin-bottom:10px!important;
}


.mt-2 {
    margin-top:20px!important;
}

.mb-2 {
    margin-bottom:20px!important;
}


.mt-12 {
    margin-top:80px!important;
}

.mb-12 {
    margin-bottom:100px!important;
}
/* Padding */
/* Padding */

.p-1 {
    padding:20px!important;
}

.p-2 {
    padding:20px!important;
}

.p-3 {
    padding:30px!important;
}


/* Width */
/* Width */

.w100 {
    width:100%!important;
}

.maxW300 {
    max-width:300px!important;
}

.maxW200 {
    max-width:200px!important;
}

.maxW100 {
    max-width:100px!important;
}

.maxW50 {
    max-width:50px!important;
}

/* Input */
/* Input */

.ErrorInput {
    border: 1px solid rgb(255, 0, 0)!important;
    border-radius:6px!important;
    box-shadow: 0 0 0 0em rgb(255, 0, 0)!important;
    border-color: rgb(255, 6, 6)!important;
}

.ErrorInput:focus {
    border: 2px solid rgb(226, 134, 134)!important;
    border-radius:6px!important;
    box-shadow: 0 0 0 0em rgb(226, 134, 134)!important;
  
}

.NormalInput {  
    border: 1px solid rgb(248, 246, 246) !important;
    border-radius:4px!important;
    border-color: rgb(230, 230, 230) !important;
}

.NormalInput:focus {  
    border: 1px solid rgb(145, 21, 202) !important;
    border-radius:4px!important;
    border-color: rgb(145, 21, 202) !important;
}


.SuccessInput {  
    border: 1px solid seagreen !important;
    border-radius:4px!important;
    border-color: seagreen!important;
}

.SuccessInput:focus {  
    border: 1px solid seagreen!important;
    border-radius:4px!important;
    box-shadow: 0 0 0 0.125em rgba(30, 218, 86, 0.45)!important;
    border-color: seagreen!important;
}

.input:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .select select:focus, .is-focused.input, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .select select.is-focused, .input:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .select select:active, .is-active.input, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .select select.is-active {
    border-color: transparent!important;
    box-shadow: 0 0 0 0em rgba(121, 87, 213, 0.25)!important;
}

.control .help.counter {
    display: none!important;
}

.input, .taginput .taginput-container.is-focusable, .textarea {
    -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0) !important;
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0) !important;
}

.help.is-danger {
    display: none !important;
}


</style>